.stay {
    padding-top: 135px;
    padding-bottom: 195px;
    background-color: #f4f8fc;
}
.stay__content {
    margin-top: 80px;
}
.stay__item {
    display: flex;
    flex-direction: column;
    position: relative;
    // max-width: 560px;
    margin: 0 20px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    overflow: hidden;
    padding-top: 90px;
    a {
        color: #ffffff;
        background-color: #e61e53;
        border-radius: 100px;
        text-decoration: none;
        font-size: 16px;    
        text-align: center;
        font-weight: 700;
        margin: 0 auto;
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: center;
        margin: 40px 20px 20px;
        cursor: pointer;
        transition: .4s;
        margin-top: auto;
        &:hover {
            background-color: rgb(184, 201, 34);
        }
    }
}
.stay__item-day  {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgb(184, 201, 34);
    padding: 0 12px;
    padding-top: 18px;
    padding-bottom: 2px;
    p {
        color: white;
        font-size: 22px;
        line-height: 1.35;
        font-weight: 600;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid #fff;
    }
}
.stay__item-schedule  {
    text-align: center;
    font-size: 18px;
    line-height: 2.5;
    font-weight: 300;
    padding-top: 34px;
    padding-bottom: 30px;
}