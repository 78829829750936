.social {
    padding-top: 90px;
    padding-bottom: 150px;
    background-color: #ffffff;
}
.social__container {
    max-width: 560px;
    margin: 0 auto;
    p {
        font-size: 25px;
        line-height: 1.55;
        font-weight: 300;
        color: #000;
        text-align: center;
    }
    a {
        margin-top: 20px;
        width: 64px;
        height: 64px;
        cursor: pointer;
        margin: 0 auto;
        display: block;
        transition: .4s;
        &:hover {
            transform: scale(1.1);
        }
    }
}