.lessons {
    padding-top: 135px;
    padding-bottom: 100px;
}
.lessons__list {
    list-style: none;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    @media (min-width: 992px) {
        max-width: 960px;
    }
}
.lessons__item {
    border-color: #dfe3e8;
    border-width: 1px;
    background-color: #f4f8fc;
    margin: 10px 0;
    width: 100%;
    position: relative;
    min-height: 300px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    @media (min-width: 576px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (min-width: 992px) {
        padding-left: 320px;    
    }
    img {
        display: block;
        position: absolute;
        left: 0;
        object-fit: cover;
        top: 0;
        height: 100%;
        width: 280px;
    }
}
.lessons__item-title {
    font-size: 30px;
    line-height: 1.17;
    font-weight: 600;
    margin-bottom: 15px;
}
.lessons__item-desc {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
}
