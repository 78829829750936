.group {
    padding-bottom: 135px;
}
.group__container {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
}
.group__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    line-height: 1.35;
    font-weight: 600;
    color: #000;
    &:last-child {
        margin-top: 30px;
    }
    @media (min-width: 768px) {
        &:last-child {
            margin-top: 0;
        }
    }
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    span {
        margin-top: 30px;
        font-weight: 300;
        color: #000;
        font-size: 14px;
        line-height: 1.55;
        strong {
            font-weight: 700;
        }
    }
}