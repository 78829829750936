@use "sass:math";

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-78787__1 {
    width: math.div(254.1, 320.71) * 1em;
}
.icon-arrow-right-long-solid {
    width: math.div(512, 512) * 1em;
}
