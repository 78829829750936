.modal {
    overflow-y: auto;
    overflow-x: hidden;
    height: 0;
    opacity: 0;
    z-index: -2;
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(102, 102, 102, 0.8);
    transition: .4s, height 0s 0.5s;
    padding: 20px;
}

.modal_opened {
    height: 100vh;
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 99;
}

.modal__container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 39px 50px;
    img {
        max-height: 100%;
        max-width: 1143px;
        width: 100%;
        object-fit: contain;
        margin: auto;
    }
}

.modal__close-button {
    z-index: 2;
    background: #F8F8F8;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: .7s;
    &:hover {
        opacity: .7;
    }
    &::after {
        content: '';
        background: #231F20;
        top: 8px;
        left: 3px;
        width: 20px;
        height: 2px;
        transition: all .3s ease 0s;
        position: absolute;
        transform: rotate(45deg);
    }
    &::before {
        content: '';
        background: #231F20;
        top: 8px;
        left: 3px;
        width: 20px;
        height: 2px;
        transition: all .3s ease 0s;
        position: absolute;
        transform: rotate(-45deg);
    }
}

.arrow {
    width: 18px;
    height: 18px;
    background: 0 0;
    position: absolute;
    top: 50%;
    border-top: 2px solid;
    border-right: 2px solid;
    cursor: pointer;
    transition: .4s;
    &:hover {
        opacity: .7;
    }
}
.arrow-left {
    left: 26px;
    transform: translateY(-50%) rotate(-135deg);
}
.arrow-right {
    right: 26px;
    transform: translateY(-50%) rotate(45deg);
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}
  
.fadeInLeft {
   animation-name: fadeInLeft;
   animation-duration: .5s;
}

@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    animation-name: fadeInRight;
    animation-duration: .5s;
  }