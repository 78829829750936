.gallery {
    padding-bottom: 90px;
}
.gallery__list {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }
    img {
        display: block;
        width: 100%;
        object-fit: cover;
        padding: 5px;
        height: 100%;
        cursor: pointer;
        transition: .4s;
        &:hover {
            opacity: .7;
        }
    }
}